<template>
    <section class="h-100">
        <div class="row mx-0 bg-white" :style="`height:calc(100vh - ${$route.name == 'admin.almacen.productos.estado' || $route.name == 'admin.almacen.productos.desconocidos' || $route.name == 'admin.almacen.productos.pedidos' ? 231 : 256}px)`">
            <div class="col px-0 border-right h-100">
                <div ref="cont1" class="p-3 f-15 overflow-auto custom-scroll" style="height:calc(100% - 8px);">
                    <div class="row mx-0 justify-center">
                        <card-producto
                        v-for="(prod, idx) in productos" :key="`prod-${idx}`"
                        :info="prod"
                        :diseno="prod.data_promocion"
                        class="cr-pointer card-prod"
                        @accion="modalProducto"
                        @icono="cambiar_icono"
                        />
                    </div>

                    <contentLoader v-show="loading" />

                    <div v-show="id_pedido === null" class="row h-100 mx-0 align-items-center justify-content-center">
                        <div class="text-center f-15 f-600 d-middle-center flex-column" style="width:225px;">
                            <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                                <img height="80" src="/img/no-imagen/click.svg" alt="" />
                            </div>
                            <p>Selecciona uno de los pedidos activos del panel de la izquierda.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modalDetalleProd ref="modalDetalleProd" />

        <modalRechazarPedido ref="modalRechazarPedido" @accion-rechazar="accion_rechazar" />
        <modalCancelarPedido ref="modalCancelarPedido" @accion-cancelar="accion_cancelar" />
        <modalChat ref="modalChat" :pedido="pedido" :leechero="cliente" @actualizarIcono="actualizarIconoChat" />

        <modalEditarPedido ref="modalEditarPedido" :editar="editar" :confirmacion="confirmar_pedido" @accion-editar="accion_editar" />

        <modalFinalizarPedido ref="modalFinalizarPedido" @credito="modalCredito(true,2)" />
        <modalStockBajo ref="modalStockBajo" />

        <!-- <modalEditarProd ref="modalEditarProd" @eliminar="modalEliminar" @update="recalcular_datos" /> -->

        <modalAgregarProductos ref="modalProductos" @update="update_lista" />
        <modalProductoDesconocido ref="modalProductoDesconocido" />

        <modal-devolver-prod ref="modalDevolverProd" :pedido="pedido" @update="recalcular_pedido" />

        <modal-enviar-pedido ref="modalEnviarPed" @accion-enviar="accion_enviar" />
        <modal-eliminar-producto ref="modalEliminarProducto" @update="recalcular_pedido" />

        <modalEditarProductoNew ref="modalEditarProductoNew" @update="recalcular_pedido" @eliminar="modalEliminar" />

        <modalConfirmar
        ref="modalFinCambios"
        titulo="Finalizar Cambios"
        :mensaje="`¿Desea enviar los cambios del pedido al ${$config.cliente}?`" icon="grocery"
        @guardar="finalizar_edicion_pedido_directo()"
        />

        <modalConfirmar
        ref="modalEliminarProd"
        titulo="Eliminar producto"
        mensaje="¿Estás seguro de eliminar el producto del pedido?" icon="shopping-cart"
        @guardar="eliminar_producto"
        />

        <modalConfirmar
        ref="modalAlistarPedido"
        titulo="Alistar Pedido"
        mensaje="¿Desea empezar a alistar el pedido?" icon="Alistar"
        adicional="Alistar"
        no-aceptar
        @adicional="confirmar_cambio_estado(201)"
        />

        <modalConfirmar
        ref="modalEmpacarPedido"
        titulo="Enviar Pedido"
        mensaje="¿Desea pasar el pedido a empacado?" icon="bicycle-l"
        @guardar="confirmar_cambio_estado(202)"
        />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Pedidos from '~/services/pedidos/pedidos-admin'

export default {
    components: {
        modalAgregarProductos: () => import('../pedidos/partials/modalAgregarProductos'),
        modalDetalleProd: () => import('../pedidos/partials/modalDetalleProducto'),
        modalRechazarPedido: () => import('../pedidos/partials/modalRechazarPedido'),
        modalCancelarPedido: () => import('../pedidos/partials/modalCancelarPedido'),
        modalEditarPedido: () => import('../pedidos/partials/modalEditarPedido'),
        modalEditarProductoNew: () => import('../pedidos/partials/modalEditarProductoNew'),
        modalDevolverProd: () => import('../pedidos/partials/modalDevolverProducto'),

        modalEnviarPedido: () => import('../pedidos/partials/modalEnviarPedido'),
        modalEliminarProducto: () => import('../pedidos/partials/modalEliminarProducto'),

        modalProductoDesconocido: () => import('../pedidos/partials/modalProductoDesconocido'),
        modalFinalizarPedido: () => import('../pedidos/partials/modalFinalizarPedido'),

        modalStockBajo: () => import('../pedidos/partials/modalStockBajo'),
        cardProducto: () => import('../pedidos/partials/card-producto-pedido2'),
    },
    data(){
        return {
            editar: false,
            confirmar_pedido:false,
            loading:false,
            cargando:false,
            id_eliminar:null
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            cliente: 'pedidos/pedidos_admin/cliente',
            productos: 'pedidos/pedidos_admin/productos',
            pedido: 'pedidos/pedidos_admin/pedido',

        }),
        rol(){
            return this.$usuario.rol_nombre
        },
    },
    watch:{
        id_pedido(val){
            if(val){
                this.listar_productos()
            }else{
                this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
                this.$store.commit('pedidos/pedidos_admin/set_pedido', {})
                this.$store.commit('pedidos/pedidos_admin/set_productos', [])
            }
        }
    },
    methods: {
        async listar_productos(){
            try {
                this.loading = true
                await this.$store.dispatch('pedidos/pedidos_admin/pedidos_productos',this.id_pedido)
                this.$store.dispatch('pedidos/pedidos_admin/pedidos_resumenes', this.id_pedido)
                this.$store.dispatch('pedidos/pedidos_admin/pedidos_historial', this.id_pedido)
                // console.log('aca');
                if(this.pedido.pago_estado === 2){
                    this.$store.dispatch('pedidos/pedidos_admin/pedido_credito', this.id_pedido)
                }
            } catch (e){
                this.error_catch(e)
            } finally{
                this.loading = false
            }
        },



        confirmarPedido(){
            this.confirmar_pedido = true
            this.editar = false
            this.$refs.modalEditarPedido.toggle()
        },
        rechazarPedido(){
            this.$refs.modalRechazarPedido.toggle()
        },
        cancelarPedido(){
            this.$refs.modalCancelarPedido.toggle()
        },
        verChat(){
            this.pedido.cant_mensajes = 0
            this.$refs.modalChat.toggle(this.id_pedido, 5, false , 1) // 5 = tipo pedido admin
        },


        modalEliminar(id_pedido_producto){
            this.id_eliminar = id_pedido_producto
            this.$refs.modalEliminarProd.toggle()
        },
        async eliminar_producto(){
            try {
                const {data} = await Pedidos.eliminar_producto(this.id_eliminar)

                this.notificacion('Mensaje','Producto eliminado','success')
                this.$refs.modalEliminarProd.toggle()
                //recalculamos todos los datos
                this.$emit('update')
                this.$store.commit('pedidos/pedidos_admin/delete_producto', this.id_eliminar)
                this.$store.dispatch('pedidos/pedidos_admin/pedidos_resumenes', Number(this.id_pedido))

            } catch (e){
                this.error_catch(e)
            }
        },

        modal_enviar_cambios(){
            this.$refs.modalEnviarCambios.toggle()
        },
        recalcular_datos(){
            this.$emit('update')
            this.$store.dispatch('pedidos/pedidos_admin/pedidos_productos', Number(this.id_pedido))
            this.$store.dispatch('pedidos/pedidos_admin/pedidos_resumenes', Number(this.id_pedido))
        },
        accion_editar(){
            this.$emit('update')
        },
        accion_cancelar(){
            this.$emit('update')
            this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
        },
        accion_rechazar(){
            this.$emit('update')
            this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
        },

        recalcular_pedido(){
            this.$store.dispatch('pedidos/pedidos_admin/pedidos_productos', Number(this.id_pedido))
            this.$store.dispatch('pedidos/pedidos_admin/pedidos_resumenes', Number(this.id_pedido))
        },
        FinalizarPedido(){
            this.$refs.modalFinalizarPedido.toggle()
        },
        async confirmar_cambio_estado(estado){
            try {
                if(this.id_pedido === null){
                    this.notificacion('Alerta','Por favor seleccione un pedido','warning')
                    return
                }
                const {data} = await Pedidos.confirmar_cambio_estado(this.id_pedido,estado)
                switch (estado){
                case 201:
                    this.$refs.modalAlistarPedido.toggle()
                    break;
                case 202:
                    this.$refs.modalEmpacarPedido.toggle()
                    break;
                case 3:
                    this.$refs.modalEnviarPedido.toggle()
                    break;

                default:
                }

                this.recalcular_pedido()
                this.notificacion('Pedido Actualizado','','success')

            } catch (e){
                this.error_catch(e)
            }
        },
        async verificar_prodructos_para_envio(){
            try {
                if(this.id_pedido === null){
                    this.notificacion('Alerta','Por favor seleccione un pedido','warning')
                    return
                }
                if(this.productos.length === 0){
                    this.notificacion('Alerta','Debe agregar almenos un (1) producto','warning')
                    return
                }

                const {data} = await Pedidos.verificar_productos(this.id_pedido)

                if(data.agotados > 0){
                    this.$store.commit('pedidos/pedidos_admin/set_productos_stock_bajo', data.productos)
                    this.$refs.modalStockBajo.toggle()
                    return
                }

                this.$refs.modalEnviarPed.toggle();

            } catch (e){
                this.error_catch(e)
            }
        },
        accion_enviar(){
            // if(this.pedido.pago_estado == 1){
            //     this.$refs.modalOfrecerCredito.toggle()
            // }
            this.recalcular_pedido()
        },
        modalCredito(res,opcion=1){
            if(opcion === 1){
                this.$refs.modalOfrecerCredito.toggle()
                if(res){
                    this.$refs.detallePedido.otorgarCredito()
                }
            }else if(opcion === 2){
                this.$emit('credito')
            }
        },
        async editar_pedido(){
            this.confirmar_pedido = false
            this.editar = true
            this.$refs.modalEditarPedido.toggle()
        },
        async cambiar_icono(obj){
            try {
                const {data} = await Pedidos.cambiar_icono(obj)
            } catch (e){
                this.error_catch(e)
            }
        },
        modalProducto(prod){
            if(!this.$can('botones_abastecimiento_gestionar_productos')){
                this.$refs.modalDetalleProd.datos_cambio(prod,this.pedido.idm_moneda)
                return
            }

            if(this.pedido.id_pedido){
                if(this.pedido.datos_pedido.estado === 11){ // Pedniente de aprobacion del cliente
                    this.$refs.modalDetalleProd.datos_cambio(prod,this.pedido.idm_moneda)
                    return
                }
            }
            // console.log(prod);
            if(this.pedido.estado === 2 || this.pedido.estado === 201 || this.pedido.estado === 202){

                if(prod.desconocido === 0){
                    this.$refs.modalEditarProductoNew.datos_basicos(prod) // modal para cambia, agregar, eliminar producto
                }
                else if(prod.desconocido === 1){
                    this.$refs.modalProductoDesconocido.toggle(prod)  // modal para cambiar un producto desconocido por uno real
                }

            }else{
                if(prod.desconocido === 1){
                    this.$refs.modalProductoDesconocido.detalle_desconocido(prod)  // modal detalle desconocido
                    return
                }
                this.$refs.modalDetalleProd.datos_cambio(prod,this.pedido.idm_moneda) // modal detalle producto
            }
        },
        update_lista(){
            this.$refs.cont1.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },

        actualizarIconoChat(){
            this.$store.commit('pedidos/pedidos/set_pedido', {...this.pedido, cant_mensajes: 0})
            // para actualizar el listado de pedidos
            // let idx_confirmado = this.confirmados.findIndex(e => e.id === this.id_pedido)
            // let idx_enviado= this.enviados.findIndex(e => e.id === this.id_pedido)
            // let idx_sin_confirmar= this.sin_confirmar.findIndex(e => e.id === this.id_pedido)
            // let idx_alistados= this.alistados.findIndex(e => e.id === this.id_pedido)
            // let idx_empacados= this.empacados.findIndex(e => e.id === this.id_pedido)
            // if(idx_confirmado >= 0){
            //     let found = this.confirmados[idx_confirmado]
            //     found.cant_mensajes = 0
            // }else if(idx_enviado >= 0){
            //     let found = this.enviados[idx_enviado]
            //     found.cant_mensajes = 0
            // }else if(idx_sin_confirmar >= 0){
            //     let found = this.sin_confirmar[idx_sin_confirmar]
            //     found.cant_mensajes = 0
            // }else if(idx_alistados >= 0){
            //     let found = this.alistados[idx_alistados]
            //     found.cant_mensajes = 0
            // }else if(idx_empacados >= 0){
            //     let found = this.empacados[idx_empacados]
            //     found.cant_mensajes = 0
            // }
        },
        async finalizar_edicion_pedido_directo(){
            try {
                const {data} = await Pedidos.finalizar_edicion_pedido_directo(this.id_pedido)
                this.recalcular_pedido()
                this.notificacion('Éxito','Los cambios fueron enviados al cliente. Esperando confirmación...','success')
                this.$refs.modalFinCambios.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        devolverProducto(){
            this.$refs.modalDevolverProd.toggle(this.productos,this.id_pedido)
        },
        accionBoton(key){
            switch (key){
            case 1:
                this.editar_pedido()
                break;
            case 2:
                this.devolverProducto()
                break;
            case 0:
                this.cancelarPedido()
                break;

            default:
                break;
            }
        }


    }
}
</script>

<style lang="scss" scoped>
.bg-rosa{
    background: #f5f5f5;
}
.text-orange{
    color: #FF612B;
}
.text-morado{
    color: #9508EB;
}
.alto{
    height: calc(100vh - 64px);
}
.bg-gr-prod{
    background: linear-gradient( to right, #03D6BC20, #0D4DFF20)
}
.trans-card:first-child{
    // background-color: #0d4dff20;
    animation-duration: 4s;
    animation-iteration-count: 1;
    animation-name: slidein;
}
.br-12{
    border-radius: 12px !important;
}
</style>
